<template>
  <div>
    <v-row class="mt-6" justify="center" align="center">
      <h1>창업디딤돌 성능평가</h1>
    </v-row>
    <v-row align="center">
      <v-col>
        <v-row class="mx-4 mb-3">
          <h3>1. 키워드 카테고리 분류기</h3>
        </v-row>
        <v-data-table
                :items-per-page="5"
                :headers="headers(categoryTestData.length > 0 ? categoryTestData : categoryTestResult)"
                :items="categoryTestData.length > 0 ? categoryTestData : categoryTestResult">
        </v-data-table>
        <v-row v-if="categoryTestResult.length > 0">
          <v-spacer></v-spacer>
          <div class="mx-6 mb-6">
            일치 개수: {{categoryTestResult.filter(obj => obj.category === obj.category_predict).length}}
            정확도: {{(categoryTestResult.filter(obj => obj.category === obj.category_predict).length / categoryTestResult.length * 100).toFixed(2)}}%
            수행시간: {{categoryTestTime.toFixed(2)}}ms
            건당 수행시간: {{(categoryTestTime/1000).toFixed(2)}}ms
          </div>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn class="mr-4" color="primary" @click="loadCategoryTestData()" style="margin:0">정답지 랜덤 로드</v-btn>
          <download-excel
              :data   = "categoryTestData"
              name    = "categoryTestData.xls">
            <v-btn color="primary" class="ma-0 white--text" :disabled="categoryTestData?.length === 0">정답지 다운로드</v-btn>
          </download-excel>
          <v-btn class="mx-4" color="primary" @click="loadCategoryTestResult()" style="margin:0" :disabled="categoryTestData?.length === 0">카테고리 예측</v-btn>
          <download-excel
              :data   = "categoryTestResult"
              name    = "categoryTestResult.xls">
            <v-btn color="primary" class="mr-2 white--text" :disabled="categoryTestResult?.length === 0">예측 결과 다운로드</v-btn>
          </download-excel>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col>
        <v-row class="mx-4 mb-3">
          <h3>2. 인플루언서 영향력 평가 수집 속도</h3>
        </v-row>
        <v-data-table
                :items-per-page="5"
                :headers="headers(influencers)"
                :items="influencers">
        </v-data-table>
        <v-row v-if="influencers.length > 0">
          <v-spacer></v-spacer>
          <div class="mx-6 mb-6">
            수행시간: {{influencersTime.toFixed(2)}}ms
            건당 수행시간: {{(influencersTime/influencers.length).toFixed(2)}}ms
          </div>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn class="mx-4" color="primary" @click="findInfluencer()" style="margin:0">수집</v-btn>
          <download-excel
              :data   = "influencers"
              name    = "influencers.xls">
            <v-btn color="primary" class="mr-2 white--text" :disabled="influencers?.length === 0">인플루언서 다운로드</v-btn>
          </download-excel>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col>
        <v-row class="mx-4 mb-3">
          <h3>3. 인플루언서 영향력 평가 속도</h3>
        </v-row>
        <v-data-table
                :items-per-page="5"
                :headers="headers(influencersEval)"
                :items="influencersEval">
        </v-data-table>
        <v-row v-if="influencersEval.length > 0">
            <v-spacer></v-spacer>
            <div class="mx-6 mb-6">
                수행시간: {{influencersEvalTime.toFixed(2)}}ms
                건당 수행시간: {{(influencersEvalTime/influencersEval.length).toFixed(2)}}ms
            </div>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn class="mx-4" color="primary" @click="evalInfluencerPrice()" style="margin:0">영향력 평가</v-btn>
          <download-excel
              :data   = "influencersEval"
              name    = "influencersEval.xls">
            <v-btn color="primary" class="mr-2 white--text" :disabled="influencersEval?.length === 0">인플루언서 평가 결과 다운로드</v-btn>
          </download-excel>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col>
        <v-row class="mx-4 mb-3">
          <h3>4. 예상 광고비용 추정 모델 예측 상대오차</h3>
        </v-row>
        <v-data-table
                :items-per-page="5"
                :headers="headers(influencersAdFee)"
                :items="influencersAdFee">
        </v-data-table>
        <v-row v-if="influencersAdFee.length > 0">
            <v-spacer></v-spacer>
            <div class="mx-6 mb-6">
              상대오차: {{(_.meanBy(influencersAdFee, 'delta') / _.meanBy(influencersAdFee, 'point') * 100).toFixed(2)}}%
            </div>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn class="mx-4" color="primary" @click="evalInfluencerAdFee()" style="margin:0">광고비용 예측 오차</v-btn>
          <download-excel
              :data   = "influencersAdFee"
              name    = "influencersAdFee.xls">
            <v-btn color="primary" class="mr-2 white--text" :disabled="influencersAdFee?.length === 0">인플루언서 상대오차 다운로드</v-btn>
          </download-excel>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center" class="mb-4">
      <v-col>
        <v-row class="mx-4 mb-3">
            <h3>5. 영향력 평가 대비 실제 마케팅 성과</h3>
        </v-row>
        <v-data-table
                :items-per-page="5"
                :headers="headers(influencersMarketing)"
                :items="influencersMarketing">
        </v-data-table>
        <v-row v-if="influencersMarketing.length > 0">
            <v-spacer></v-spacer>
            <div class="mx-6 mb-6">
                성과: {{(_.sumBy(influencersMarketing, 'point') / _.sumBy(influencersMarketing, 'views')).toFixed(2)}}원
            </div>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn class="mx-4" color="primary" @click="evalInfluencerMarketing()" style="margin:0">마케팅 성과</v-btn>
          <download-excel
              :data   = "influencersMarketing"
              name    = "influencersMarketing.xls">
            <v-btn color="primary" class="mr-2 white--text" :disabled="influencersMarketing?.length === 0">마케팅 성과 다운로드</v-btn>
          </download-excel>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import downloadExcel from "vue-json-excel";
  export default {
    name: 'Test',
    components: {
      downloadExcel
    },
    data: () => ({
      categoryTestData: [],
      categoryTestResult: [],
      categoryTestTime: 0,
      influencers: [],
      influencersTime: 0,
      influencersEval: [],
      influencersEvalTime: 0,
      influencersAdFee: [],
      influencersMarketing: []
    }),
    computed: {
    },
    methods: {
      headers(arr) {
        let s = new Set();
        arr.forEach(item => {
          for(let f in item) {
            s.add(f)
          }
        });
        return Array.from(s).map(a => {
          return {
            text: a.toUpperCase(),
            value: a
          }
        });
      },
      async loadCategoryTestData() {
        const response = await this.axios.get('http://ai.superchart.com:8000/test/data');
        this.categoryTestData = response.data;
        this.categoryTestResult = [];
        this.categoryTestTime = 0;
        console.log(response.data);
      },
      async loadCategoryTestResult() {
        const start = performance.now();
        const response = await this.axios.get('http://ai.superchart.com:8000/test/predict');
        const end = performance.now();
        this.categoryTestTime = end - start;
        this.categoryTestResult = response.data;
        this.categoryTestData = [];
        console.log(response.data);
      },
      async findInfluencer() {
        const start = performance.now();
        const response = await this.axios.get('http://54.180.162.137/influencer/collect');
        const end = performance.now();
        this.influencersTime = end - start;
        this.influencers = response.data.map((influencer) => ({id: influencer}))
        console.log(response.data);
      },
      async evalInfluencerPrice() {
        const start = performance.now();
        const response = await this.axios.get('http://54.180.162.137/influencer/eval');
        const end = performance.now();
        this.influencersEvalTime = end - start;
        this.influencersEval = response.data;
        console.log(response.data);
      },
      async evalInfluencerAdFee() {
        const response = await this.axios.get('http://54.180.162.137/influencer/adFee');
        this.influencersAdFee = response.data;
        console.log(response.data);
      },
      async evalInfluencerMarketing() {
        const response = await this.axios.get('http://54.180.162.137/influencer/marketing');
        this.influencersMarketing = response.data;
        console.log(response.data);
      }
    }
  }
</script>